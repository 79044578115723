import { render, staticRenderFns } from "./LimitInfoSetBox.vue?vue&type=template&id=2c7e6c1f&scoped=true&"
import script from "./LimitInfoSetBox.vue?vue&type=script&lang=js&"
export * from "./LimitInfoSetBox.vue?vue&type=script&lang=js&"
import style0 from "./LimitInfoSetBox.vue?vue&type=style&index=0&id=2c7e6c1f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7e6c1f",
  null
  
)

export default component.exports